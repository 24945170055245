import Date2Field, { Date2FieldProps } from './Date2Field';

export function DateTime2Field(props: DateTime2FieldProps) {
    return <Date2Field kind={'dateTime'} type={'datetime-local'} {...props} />;
}

export type DateTime2FieldProps = Date2FieldProps;

// noinspection JSUnusedGlobalSymbols
export default DateTime2Field;
